var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Report Stock Adjustment" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    },
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                        return _vm.submitForm.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 24,
                                            md: 12,
                                            lg: 12,
                                            xl: 12
                                          }
                                        },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.no.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.no
                                                          .decorator,
                                                      expression:
                                                        "formRules.no.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: _vm.formRules.no.name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.no
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading: _vm.loadingNo,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getStockAdjustment(
                                                        value
                                                      )
                                                    },
                                                    change:
                                                      _vm.handleStockAdjustment
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataStockAdjustment,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.documentNumber
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.documentNumber
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.branch.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.branch
                                                          .decorator,
                                                      expression:
                                                        "formRules.branch.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.branch.name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.branch
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading: _vm.loadingBranch,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getBranch(
                                                        value
                                                      )
                                                    },
                                                    change: _vm.handleBranch
                                                  }
                                                },
                                                _vm._l(_vm.dataBranch, function(
                                                  data,
                                                  index
                                                ) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: index,
                                                      attrs: { value: data.id }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.name
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.warehouse.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.warehouse
                                                          .decorator,
                                                      expression:
                                                        "formRules.warehouse.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.warehouse
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.warehouse
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loadingLocationWarehouse,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListWarehouse(
                                                        value,
                                                        _vm.branchId
                                                      )
                                                    },
                                                    change:
                                                      _vm.handleChangeWarehouse
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataListLocationWarehouse,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.name
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.rack.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.rack
                                                          .decorator,
                                                      expression:
                                                        "formRules.rack.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.rack.name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.rack
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loadingLocationRack,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListLocationRack(
                                                        value,
                                                        _vm.idWarehouse
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataListLocationRack,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.name
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.adj.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.adj
                                                          .decorator,
                                                      expression:
                                                        "formRules.adj.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.adj
                                                        .placeholder
                                                    ),
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    change: _vm.handleSelectType
                                                  }
                                                },
                                                _vm._l(_vm.optType, function(
                                                  item
                                                ) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: item.key,
                                                      attrs: { value: item.key }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.key) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.date.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-range-picker", {
                                                attrs: {
                                                  ranges: {
                                                    Today: [
                                                      _vm.moment(),
                                                      _vm.moment()
                                                    ],
                                                    "This Month": [
                                                      _vm.moment(),
                                                      _vm
                                                        .moment()
                                                        .endOf("month")
                                                    ]
                                                  },
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT,
                                                  type: "date"
                                                },
                                                on: {
                                                  change:
                                                    _vm.onRangePickerChange
                                                },
                                                model: {
                                                  value: _vm.modelForm.date,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.modelForm,
                                                      "date",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "modelForm.date"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "a-space",
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: _vm.handleCancel
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("lbl_reset")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "a-button",
                                                {
                                                  attrs: {
                                                    loading: _vm.loadingSubmit,
                                                    type: "primary"
                                                  },
                                                  on: { click: _vm.onSubmit }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("lbl_find")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-2", attrs: { span: 24 } },
                            [
                              _c(
                                "a-tabs",
                                { attrs: { type: "card" } },
                                [
                                  _c(
                                    "a-tab-pane",
                                    {
                                      key: "1",
                                      staticClass: "bordertabs p-2",
                                      attrs: { tab: "Details" }
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c("TableCustom", {
                                                attrs: {
                                                  dataSource: _vm.dataSource,
                                                  columns: _vm.columnsTable,
                                                  scroll: {
                                                    x: "calc(700px + 50%)",
                                                    y: 500
                                                  },
                                                  paginationcustom: true,
                                                  defaultPagination: false,
                                                  loading: _vm.loadingTable
                                                },
                                                on: {
                                                  "on-columnClicked":
                                                    _vm.handleClickColumn
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "a-space",
                                                {
                                                  staticStyle: {
                                                    height: "50px"
                                                  },
                                                  attrs: { align: "center" }
                                                },
                                                [
                                                  _c("Pagination", {
                                                    attrs: {
                                                      total:
                                                        _vm.totalElements === 0
                                                          ? 1
                                                          : _vm.totalElements,
                                                      pageSizeSet: _vm.limit,
                                                      idPagination:
                                                        "pagination1"
                                                    },
                                                    on: {
                                                      "response-pagesize-change":
                                                        _vm.responsePageSizeChange,
                                                      "response-currentpage-change":
                                                        _vm.responseCurrentPageChange
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { span: 12, align: "end" }
                                            },
                                            [
                                              _c(
                                                "a-tag",
                                                {
                                                  staticStyle: {
                                                    "font-size": "13px"
                                                  },
                                                  attrs: { color: "#8c8c8c" }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_found"
                                                        )
                                                      ) +
                                                      " : " +
                                                      _vm._s(
                                                        _vm.totalElements
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            {
                                              attrs: { span: 24, align: "end" }
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "0.5rem"
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    loading: _vm.loadingDownload
                                                  },
                                                  on: {
                                                    click: _vm.handleDownload
                                                  }
                                                },
                                                [_vm._v(" Download ")]
                                              ),
                                              _c(
                                                "a-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    loading: _vm.loadingPrint
                                                  },
                                                  on: { click: _vm.handlePrint }
                                                },
                                                [
                                                  _c("a-icon", {
                                                    staticStyle: {
                                                      "vertical-align": "0"
                                                    },
                                                    attrs: { type: "printer" }
                                                  }),
                                                  _vm._v("Print ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }